<template>
  <div style="background-color: #fff;">
    <div class="flex flex-between">
      <div>
        <templateTitle title="职位管理" :isBack="false"></templateTitle>
      </div>
      <x-card >
        <div slot="content" class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row>
              <a-col>
                <a-form-item>
                  <a-input v-model="queryParam.name" allow-clear placeholder="请输入职位名称" />
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item>
                  <a-button @click="$refs.addForm.add()" type="primary" ghost v-if="hasPerm('companyMag:pos:add')"
                    >新建</a-button
                  >
                  <a-button type="primary" ghost @click="$refs.table.refresh(true)">查询</a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </x-card>
    </div>
    <div class="table-padding">
      <a-card :bordered="false">
        <s-table
          ref="table"
          bordered
          :columns="columns"
          :data="loadData"
          :alert="true"
          :rowKey="record => record.code"
        > 
          <s-column title="序号" dataIndex="index" align="center" :width="55">
            <template slot="customRender" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
          </s-column>  
          <span slot="action" slot-scope="text, record">
            <a v-if="hasPerm('companyMag:pos:edit')" @click="$refs.editForm.edit(record)">编辑</a>
            <a-divider type="vertical" v-if="hasPerm('companyMag:pos:edit') & hasPerm('companyMag:pos:delete')" />
            <a-popconfirm
              v-if="hasPerm('companyMag:pos:delete')"
              placement="topRight"
              title="确认删除？"
              @confirm="() => sysPosDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </span>
        </s-table>

        <add-form ref="addForm" @ok="handleOk" />
        <edit-form ref="editForm" @ok="handleOk" />
      </a-card>
    </div>
  </div>
</template>

<script>
import { STable, XCard } from '@/components'
import { sysPosPage, sysPosDelete } from '@/api/modular/system/posManage'
import addForm from './addForm'
import editForm from './editForm'
import templateTitle from '@/components/templateTitle.vue'

export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm,
    templateTitle
  },

  data() {
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 70,
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '职位名称',
          dataIndex: 'name'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return sysPosPage(Object.assign(parameter, this.queryParam)).then(res => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },

  created() {
    if (this.hasPerm('companyMag:pos:edit') || this.hasPerm('companyMag:pos:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },

  methods: {
    sysPosDelete(record) {
      sysPosDelete(record)
        .then(res => {
          if (res.success) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('删除失败：' + res.message)
          }
        })
        .catch(err => {
          this.$message.error('删除错误：' + err.message)
        })
    },

    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>

<style lang="less">
.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}
</style>

<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';

.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}

::v-deep .s-table-tool .s-table-tool-right {
  display: none;
}

.ant-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

::v-deep .ant-card-body {
  padding: 0 !important;
}

::v-deep .table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: 10px;
}
::v-deep .ant-table-small.ant-table-bordered {
  border: 0;
}

::v-deep .ant-table-small.ant-table-bordered {
  height: 550px;
}

::v-deep .ant-table-thead > tr > th:last-child {
  border-right: none !important;
}
::v-deep .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}
/* 去掉右侧边框 */
::v-deep .ant-table-small.ant-table-bordered .ant-table-content {
  border-bottom: 1px solid #e8e8e8;
  border-right: 0px !important;
}

::v-deep .ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
::v-deep .ant-table {
  border: 1px solid #e8e8e8 !important;
}
//注意一定要在Table表格下的样式修改，并且要有:global{}包裹
</style>
